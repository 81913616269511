import { defineStore } from 'pinia'
import { computed } from 'vue'

import { useStore } from '@/store/useStore'
import type { ModelType } from '@/core/annotations'
import { FilterSubject } from '@/modules/AdvancedFilters/FilterSubject'
import { FilterOperator } from '@/modules/AdvancedFilters/FilterOperator'
import { WorkflowStatus } from '@/modules/AdvancedFilters/WorkflowStatus'
import { createAdvancedFilter } from '@/modules/AdvancedFilters/filterFactory'
import type {
  ModelTemplatePayload,
  TrainingClass,
  TrainingSessionPayload,
} from '@/backend/wind/types'
import { ModelDevice } from '@/backend/wind/types'
import type { ApiResult } from '@/backend/darwin/types'
import { loadV2DatasetGeneralCounts } from '@/backend/darwin/loadV2DatasetGeneralCounts'
import { assert } from '@/core/utils/assert'
import type { AnnotationClassPayload } from '@/store/types/AnnotationClassPayload'
import type { DatasetPayload } from '@/store/types/DatasetPayload'

import {
  loadPublishedModelTemplates as loadPublishedModelTemplatesFromWind,
  trainModel as trainModelFromWind,
} from '@/backend/wind'
import { loadV2DatasetItems } from '@/backend/darwin/loadV2DatasetItems'
import {
  fetchMainAnnotationType,
  getSubAnnotationTypes,
  type AnnotationType,
} from '@/core/annotationTypes'
import type { ValidationError } from '@/store/types/ValidationError'
import type { ParsedValidationError } from '@/backend/error/types'

import { WindErrorCodes } from '@/backend/error/errors'

const annotationClassToTrainingClass = (
  annotationClass: AnnotationClassPayload,
  type: AnnotationType,
  subs: AnnotationType[],
): Omit<TrainingClass, 'id'> => {
  const { id, name } = annotationClass
  return { darwin_id: id, name, type, subs }
}

/**
 * This Pinia store is used when training a new model. It holds the state of the
 * model to be trained, and sends a request to Wind to train the model.
 */
export const useModelCreationStore = defineStore('modelCreation', () => {
  const { commit, state } = useStore()

  const modelTemplates = computed(() => state.neuralModel.modelTemplates)

  const loadPublishedModelTemplates = async (teamId: number): Promise<void> => {
    const response = await loadPublishedModelTemplatesFromWind({ teamId })

    if ('data' in response) {
      // eslint-disable-next-line no-restricted-syntax
      commit('neuralModel/SET_MODEL_TEMPLATES', response.data)
    }
  }

  const newModelAnnotationClasses = computed(() => state.neuralModel.newModelAnnotationClasses)
  const newModelClassCounts = computed(() => state.neuralModel.newModelClassCounts)

  const newModelSelectedClassIds = computed(() => state.neuralModel.newModelSelectedClassIds)
  const deselectAllNewModelClasses = (): void => {
    // eslint-disable-next-line no-restricted-syntax
    commit('neuralModel/DESELECT_ALL_NEW_MODEL_CLASSES')
  }
  const setNewModelClasses = (classes: AnnotationClassPayload[]): void => {
    // eslint-disable-next-line no-restricted-syntax
    commit('neuralModel/SET_NEW_MODEL_CLASSES', classes)
  }
  const setNewModelSelectedClasses = (classes: AnnotationClassPayload[]): void => {
    // eslint-disable-next-line no-restricted-syntax
    commit('neuralModel/SET_NEW_MODEL_SELECTED_CLASSES', classes)
  }
  const toggleNewModelClassSelection = (annotationClass: AnnotationClassPayload): void => {
    // eslint-disable-next-line no-restricted-syntax
    commit('neuralModel/TOGGLE_NEW_MODEL_CLASS_SELECTION', annotationClass)
  }

  const newModelDataset = computed(() => state.neuralModel.newModelDataset)
  const setNewModelDataset = (dataset: DatasetPayload): void => {
    // eslint-disable-next-line no-restricted-syntax
    commit('neuralModel/SET_NEW_MODEL_DATASET', dataset)
  }

  const newModelTemplate = computed(() => state.neuralModel.newModelTemplate)
  const setNewModelTemplate = (template: ModelTemplatePayload | null): void => {
    // eslint-disable-next-line no-restricted-syntax
    commit('neuralModel/SET_NEW_MODEL_TEMPLATE', template)
  }

  const newModelType = computed(() => state.neuralModel.newModelType)
  const setNewModelType = (modelType: ModelType): void => {
    // eslint-disable-next-line no-restricted-syntax
    commit('neuralModel/SET_NEW_MODEL_TYPE', modelType)
  }

  const newModelName = computed(() => state.neuralModel.newModelName)
  const setNewModelName = (name: string): void => {
    // eslint-disable-next-line no-restricted-syntax
    commit('neuralModel/SET_NEW_MODEL_NAME', name)
  }

  const newModelSampleItemsV2 = computed(() => state.neuralModel.newModelSampleItemsV2)
  const loadSampleDatasetItems = async (): Promise<void> => {
    const newModelDataset = assert(state.neuralModel.newModelDataset, 'No dataset selected')

    const statusFilter = {
      subject: FilterSubject.WorkflowStatus,
      matcher: {
        name: FilterOperator.AnyOf,
        values: [WorkflowStatus.Complete],
      },
    }

    const response = await loadV2DatasetItems({
      include_thumbnails: true,
      page: {
        from: state.neuralModel.newModelSampleItemsCursor || undefined,
        size: 20,
      },
      filter: createAdvancedFilter(statusFilter),
      dataset_ids: [newModelDataset.id],
      teamSlug: newModelDataset.team_slug,
    })

    if (response.ok && 'items' in response.data) {
      // eslint-disable-next-line no-restricted-syntax
      commit('neuralModel/PUSH_NEW_MODEL_SAMPLE_ITEMS_V2', response.data.items)
      // eslint-disable-next-line no-restricted-syntax
      commit('neuralModel/SET_NEW_MODEL_SAMPLE_ITEMS_CURSOR', response.data.page.next)
    }
  }

  const newModelValidationErrors = computed(() => state.neuralModel.newModelValidationErrors)

  const newModelTrainingCounts = computed(() => state.neuralModel.newModelTrainingCounts)
  const loadNewModelTrainingCounts = async (): Promise<void> => {
    const newModelDataset = assert(state.neuralModel.newModelDataset, 'No dataset selected')
    const newModelSelectedClassIds = assert(
      state.neuralModel.newModelSelectedClassIds,
      'No classes selected',
    )

    // we load counts for the selected dataset, selected classes, completed items only
    const classFilter = {
      subject: FilterSubject.AnnotationClass,
      matcher: {
        name: FilterOperator.AnyOf,
        values: newModelSelectedClassIds,
      },
    }

    const statusFilter = {
      subject: FilterSubject.WorkflowStatus,
      matcher: {
        name: FilterOperator.AnyOf,
        values: [WorkflowStatus.Complete],
      },
    }

    const response = await loadV2DatasetGeneralCounts({
      teamSlug: newModelDataset.team_slug,
      dataset_ids: [newModelDataset.id],
      filter: createAdvancedFilter(classFilter, statusFilter),
    })

    if ('data' in response) {
      // eslint-disable-next-line no-restricted-syntax
      commit('neuralModel/SET_NEW_MODEL_TRAINING_COUNTS_V2', {
        counts: response.data,
        datasetId: newModelDataset.id,
      })
    }
  }

  const trainModel = async (): Promise<ApiResult<TrainingSessionPayload>> => {
    const { newModelValidationErrors: errors } = state.neuralModel

    if (Object.keys(errors).length > 0) {
      const error: ParsedValidationError = {
        errors: errors as ValidationError,
        isValidationError: true,
        message: 'Data is invalid',
      }

      return { error, ok: false }
    }

    const { newModelAnnotationClasses, newModelName, newModelSelectedClassIds } = state.neuralModel

    const newModelDataset = assert(state.neuralModel.newModelDataset, 'No dataset selected')
    const newModelTemplate = assert(state.neuralModel.newModelTemplate, 'No template selected')

    const {
      id: datasetId,
      slug: datasetSlug,
      team_id: teamId,
      team_slug: teamSlug,
    } = newModelDataset

    const classes = newModelAnnotationClasses
      .filter((c) => newModelSelectedClassIds.includes(c.id))
      .map((c) => {
        const mainType = fetchMainAnnotationType(c.annotation_types)
        const subTypes = getSubAnnotationTypes(c.annotation_types)
        return annotationClassToTrainingClass(c, mainType, subTypes)
      })

    const params = {
      datasetId,
      datasetSlug,
      device: ModelDevice.GPU,
      classes,
      modelTemplateId: newModelTemplate.id,
      name: newModelName,
      teamId,
      teamSlug,
    }

    const response = await trainModelFromWind(params)
    if (!response.ok && 'isValidationError' in response.error && response.error.isValidationError) {
      // eslint-disable-next-line no-restricted-syntax
      commit('neuralModel/SET_NEW_MODEL_VALIDATION_ERRORS_FROM_BACKEND', response.error)
      return response
    }

    if (!response.ok && response.error.code === WindErrorCodes.NO_PAYMENT_METHOD) {
      const message = 'You need to provide a valid payment method in order to train models'
      return { error: { ...response.error, message }, ok: false }
    }

    if (
      !response.ok &&
      response.error.code === WindErrorCodes.PARTNER_DOES_NOT_COVER_NEURAL_NETWORKS
    ) {
      const message = [
        'Your partner does not cover your neural network costs.',
        'You will have to discuss and change your relationship with them to start training models.',
      ].join(' ')

      return { error: { ...response.error, message }, ok: false }
    }

    return response
  }

  // eslint-disable-next-line no-restricted-syntax
  const validateNewModel = (): void => commit('neuralModel/VALIDATE_NEW_MODEL')

  const reset = (): void => {
    // eslint-disable-next-line no-restricted-syntax
    commit('neuralModel/RESET_ALL')
  }

  return {
    modelTemplates,
    loadPublishedModelTemplates,

    newModelTemplate,
    setNewModelTemplate,

    newModelType,
    setNewModelType,

    newModelName,
    setNewModelName,

    newModelAnnotationClasses,
    newModelSelectedClassIds,
    newModelClassCounts,
    deselectAllNewModelClasses,
    setNewModelClasses,
    setNewModelSelectedClasses,
    toggleNewModelClassSelection,

    newModelDataset,
    setNewModelDataset,

    newModelSampleItemsV2,
    loadSampleDatasetItems,

    newModelTrainingCounts,
    loadNewModelTrainingCounts,

    newModelValidationErrors,
    trainModel,

    validateNewModel,

    reset,
  }
})
