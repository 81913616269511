import type { IPoint } from '@/modules/Editor/point'
import type { ImageManipulationFilter } from '@/modules/Editor/imageManipulation'
import { getCSSFilterString } from '@/modules/Editor/utils'

export class HTMLImageLayer {
  private scale = 1

  private originalWidth = 1
  private originalHeight = 1

  private readonly imgElement: HTMLImageElement = document.createElement('img')
  private readonly _element = document.createElement('div')

  constructor(public readonly name: string) {
    this.imgElement.style.position = 'absolute'
    this.imgElement.style.pointerEvents = 'none'

    this._element.style.overflow = 'hidden'
    this._element.style.position = 'absolute'
    this._element.style.top = '0'
    this._element.style.left = '0'
    this._element.style.width = '100%'
    this._element.style.height = '100%'
    this._element.appendChild(this.imgElement)
  }

  public setImageDimensions(width: number, height: number): void {
    this.originalWidth = width
    this.originalHeight = height
    this.setScale(this.scale)
  }

  public setOffset(offset: IPoint): void {
    this.imgElement.style.top = `${-offset.y}px`
    this.imgElement.style.left = `${-offset.x}px`
  }

  public setScale(scale: number): void {
    this.scale = scale
    this.imgElement.width = this.originalWidth * this.scale
    this.imgElement.height = this.originalHeight * this.scale
  }

  public setFilters(filters: ImageManipulationFilter): void {
    this.imgElement.style.filter = getCSSFilterString(filters)
    this.imgElement.style.imageRendering = ''
    this.imgElement.style.imageRendering = filters.isImageSmoothing ? 'smooth' : 'pixelated'
  }

  public hideLayer(): void {
    this.imgElement.style.display = 'none'
  }

  public showLayer(): void {
    this.imgElement.style.display = 'block'
  }

  public get canvas(): HTMLImageElement {
    return this.imgElement
  }

  public get element(): HTMLDivElement {
    return this._element
  }

  public setImageURL(url: string): void {
    this.imgElement.src = url
  }

  public changed(): void {
    // no-op, for compatibility with other layers
  }

  public render(): void {
    // no-op, for compatibility with other layers
  }

  public destroy(): void {
    this.imgElement.remove()
  }
}
