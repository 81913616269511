import type { Commit, Dispatch, StoreOptions } from 'vuex'

import type { PartialRecord } from '@/core/helperTypes'
import type { ParsedError, ParsedErrorWithMessage } from '@/backend/error'

import type { DatasetState } from './modules/dataset/state'
import type { DatasetItemReportsState } from './modules/datasetItemReports/state'
import type { DatasetUploadState } from './modules/datasetUpload/state'
import type { NeuralModelState } from './modules/neuralModel'
import type { TeamState } from './modules/team/state'
import type { UIState } from './modules/ui'
import type { WorkviewState } from './modules/workview'

export type Ability = {
  /**
   * String defining the type of subject this ability applies to.
   *
   * Generally not evaluated during authorization and mainly serves as a debug
   * tool, except for one specific case.
   *
   * If `subject` is `all`, no further checks are made and the current user is
   * allowed to perform actions in this ability on any resource they want.
   */
  subject: string | object

  /**
   * List of actions covered by this ability. The current resource can perform
   * all actions listed on
   *
   * - any resource, if `subject` is `all`
   * - any resource which satisfies given conditions, if they are given
   */
  actions: string[]

  /**
   * Optionally adds conditions which a resource must satisfy in order to
   * perform any of the listed actions.
   *
   * If a resource is a membership, which has the shape of
   *
   * ```
   * { id, user_id, team_id, role }
   * ```
   *
   * Then the condition could be something like
   *
   * ```
   * { role: 'annotator' }
   * ```
   *
   * That means the current user is authorized to perform actions listed in this
   * ability to members of role `annotator` and only those members.
   *
   * If a different shape of resource is given, or it has a different rule, the
   * current user is not allowed to perform any actions in this ability.
   *
   * The condition could also be an array, such as
   *
   * ```
   * { role: ['annotator', 'member'] }
   * ```
   *
   * In that case, the current user is authorized to perform actions listed in
   * this ability to members of roles either `annotator` or `member`.
   */
  conditions?: { [s: string]: number | string | string[] | boolean }
}

export type AbilityOptions = {
  subject: string | undefined
  // Anything can be passed in here and anything can be compared with anything,
  // including nested arrays and nested objects. We should aim to make this a
  // simple record instead
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resource: any | null
}

export enum LoadingStatus {
  Unloaded = 'unloaded',
  Loading = 'loading',
  Loaded = 'loaded',
}

/**
 * Standardized return type for a store action.
 * Contains a data key action succeeded, error key if it failed.
 */
export type ActionResponse<T = unknown> =
  | Promise<{ data: T } | ParsedError | ParsedErrorWithMessage | void>
  | { data: T }
  | void

/**
 * Standardized type definition for a store action
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TypedAction<S = any, P = any, R = any> = (
  context: {
    state: S
    rootState: RootState
    commit: Commit
    dispatch: Dispatch
    // In Vuex, `getters` and `rootGetters` are just typed as plain `any`,
    // so this is at least a small step better.
    // We can drop it fully when we migrate to pinia
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getters: PartialRecord<string, any>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rootGetters: PartialRecord<string, any>
  },
  payload: P,
) => ActionResponse<R>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TypedMutation<S = any, P = any> = (state: S, payload: P) => void

// We need to allow for any here to allow for type inforence when
// passing `typeof someFunction` into this generic. `unknown` doesn't work in that case
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type StoreActionPayload<T extends TypedAction<any, any, any>> = Parameters<T>[1]
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type StoreActionResponse<Action extends TypedAction<any, any, any>> =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Action extends TypedAction<any, any, infer Response> ? { data: Response } | ParsedError : never
export type StoreMutationPayload<T extends TypedMutation> = Parameters<T>[1]

// TODO: as modules convert to .ts, we need to set correct type for each module
export interface RootState {
  version?: string
  dataset: DatasetState
  datasetUpload: DatasetUploadState
  neuralModel: NeuralModelState
  team: TeamState
  ui: UIState
  workview: WorkviewState
  datasetItemReports: DatasetItemReportsState
}

export type Store = StoreOptions<RootState>

export type ValidationErrors = {
  [s: string]: string | [string] | ValidationErrors | ValidationErrors[]
}

export * from '@/store/types/AnnotationActorPayload'
export * from '@/store/types/AnnotationClassPayload'
export * from '@/store/types/AnnotationHotkeysPayload'
export * from '@/store/types/AnnotationTypePayload'
export * from '@/store/types/AttributePayload'
export * from '@/store/types/ClassUsagePayload'
export * from '@/store/types/ClientTeamInvitationPayload'
export * from '@/store/types/CreditUsagePayload'
export * from '@/store/types/Dataset'
export * from '@/store/types/DatasetDetailPayload'
export * from '@/store/types/DatasetExportPayload'
export * from '@/store/types/DatasetFolderPayload'
export * from '@/store/types/DatasetItemCountsPayload'
export * from '@/store/types/DatasetItemFilenamePayload'
export * from '@/store/types/DatasetItemFilter'
export * from '@/store/types/DatasetItemStatus'
export * from '@/store/types/DatasetItemType'
export * from '@/store/types/DatasetPayload'
export * from '@/store/types/DatasetReportPayload'
export * from '@/store/types/DatasetUploadedItemsPayload'
export * from '@/store/types/DatasetUploadItemPayload'
export * from '@/store/types/FeaturePayload'
export * from '@/store/types/ImagePayload'
export * from '@/store/types/InputTag'
export * from '@/store/types/InstanceCountReportPayload'
export * from '@/store/types/InvitationPayload'
export * from '@/store/types/Login2FAResponsePayload'
export * from '@/store/types/LoginResponsePayload'
export * from '@/store/types/LogoutResponsePayload'
export * from '@/store/types/MembershipPayload'
export * from '@/store/types/MembershipRole'
export * from '@/store/types/NotificationMessagePayload'
export * from '@/store/types/NotificationPayload'
export * from '@/store/types/PaginationTypes'
export * from '@/store/types/ReviewStatus'
export * from '@/store/types/Setup2FAResponsePayload'
export * from '@/store/types/SkippedReason'
export * from '@/store/types/StageActionType'
export * from '@/store/types/StageAnnotationPayload'
export * from '@/store/types/StageType'
export * from '@/store/types/StoragePayload'
export * from '@/store/types/StorageProvider'
export * from '@/store/types/TeamPayload'
export * from '@/store/types/TeamUploadInfoPayload'
export * from '@/store/types/UserPayload'
export * from '@/store/types/V2CommentPayload'
export * from '@/store/types/V2CommentThreadPayload'
export * from '@/store/types/V2DatasetFolderPayload'
export * from '@/store/types/V2DatasetItemFilter'
export * from '@/store/types/V2DatasetItemPayload'
export * from '@/store/types/V2DatasetItemTimeSummaryPayload'
export * from '@/store/types/V2DatasetItemSlot'
export * from '@/store/types/V2InstanceStatus'
export * from '@/store/types/V2WorkflowEdgePayload'
export * from '@/store/types/V2WorkflowItemPayload'
export * from '@/store/types/V2WorkflowItemStatePayload'
export * from '@/store/types/V2WorkflowPayload'
export * from '@/store/types/V2WorkflowStageConfigPayload'
export * from '@/store/types/V2WorkflowStageInstancePayload'
export * from '@/store/types/V2WorkflowStagePayload'
export * from '@/store/types/ValidationError'
export * from '@/store/types/VideoFramePayload'
export * from '@/store/types/WorkforceManagerPayload'

export type ApiResponse<T> = { data: T }
