import { defineStore } from 'pinia'
import { useClasses } from './useClasses'
import { computed, ref } from 'vue'
import { useDatasetStore } from '@/modules/Datasets/useDatasetStore'
import type {
  MaybeProperty,
  Property,
  TeamItemProperty,
  TeamProperty,
} from '@/store/types/PropertyTypes'
import { TeamPropertyType } from '@/store/types/PropertyTypes'
import { isTeamItemProperty } from '@/store/types/PropertyTypes'
import { useTeamProperties } from '@/modules/Classes/useTeamProperties'
import type { AnnotationClassPayload } from '@/store/types/AnnotationClassPayload'
import { useTeamProperty } from '@/modules/Classes/useTeamProperty'
import without from 'lodash/without'
import { useAnnotationClassProperty } from './useAnnotationClassProperty'
import { type AnnotationType, getMainAnnotationType } from '@/core/annotationTypes'
import type { PartialRecord } from '@/core/helperTypes'
import { useFeatureFlagsStore } from '@/pinia/useFeatureFlagsStore'
import { useClassesById } from './useClassesById'
import { isUUID } from 'validator'
import type { SortDirectionType } from '@/uiKit/types'
import { SortDirection } from '@/uiKit/types'
import { generalUICopy } from './generalUICopy'
import orderBy from 'lodash/orderBy'
import { LoadingStatus } from '@/store/types'
import type { OntologyClass, OntologyClassSortType, OntologyClassType } from './ontologyTypes'
import { isAnnotationClass } from './isAnnotationClass'

/**
 * This store is used in the classes (or possibly ontology in the future) page.
 * It is responsible for bringing together annotation classes and item properties.
 * In the context of Ontology, "classes" are both annotation classes and item properties
 * It supports all operations possible from the classes page, such as CRUD and filter, search and
 * sorting, as well as bulk actions
 *
 * It is not to be mistaken with `useClasses`, which still exists, and is responsible for direct
 * operations on annotation classes
 */
export const useOntology = defineStore('ontology', () => {
  const datasetStore = useDatasetStore()
  const propertiesStore = useTeamProperties()
  const propertyStore = useTeamProperty()
  const classStore = useClasses()
  const featuresStore = useFeatureFlagsStore()
  const { classesById } = useClassesById()
  const { teamToClassProperty } = useAnnotationClassProperty()

  /** takes all the team properties and filter those that are at item level */
  const itemProperties = computed<TeamItemProperty[]>(() =>
    propertiesStore.properties.filter(isTeamItemProperty),
  )

  // TODO: this is not dynamically updating while filtering anymore, is it important?
  // A reminder is set to check with Jade when she is back
  const countByType = computed(() => {
    const result: PartialRecord<OntologyClassType, number> = {}
    for (const annotationClass of classStore.teamClasses) {
      annotationClass.annotation_types.forEach(
        (type: OntologyClassType) => (result[type] = (result[type] || 0) + 1),
      )
    }
    for (const itemProperty of itemProperties.value) {
      result[itemProperty.type] = (result[itemProperty.type] || 0) + 1
    }
    return result
  })

  /**
   * All available types for ontology. Currently the union of annotation class types and item
   * property types (when FF is on)
   */
  const allTypes = computed(() => {
    const itemPropertyTypes = featuresStore.featureFlags.ITEM_LEVEL_PROPERTIES
      ? [TeamPropertyType.SINGLE_SELECT, TeamPropertyType.MULTI_SELECT]
      : []
    return [...itemPropertyTypes, ...classStore.mainTypes]
  })

  /**
   * These are all ontology classes (annotation class + item properties) that have been added
   * to a dataset (not filtered yet, the entire list)
   */
  const allDatasetClasses = computed(() => {
    const datasetId = datasetStore.currentDataset?.id
    if (!datasetId) {
      return []
    }
    return [
      ...itemProperties.value.filter((property) => property.dataset_ids?.includes(datasetId)),
      ...classStore.datasetClasses,
    ]
  })

  /**
   * all annotation classes and item properties not added to the current dataset
   */
  const allUnassignedClasses = computed(() => [
    ...itemProperties.value.filter(
      (property) =>
        !datasetStore.currentDataset?.id ||
        !property.dataset_ids?.includes(datasetStore.currentDataset.id),
    ),
    ...classStore.unassignedTeamClasses,
  ])

  /** The concept of class, in ontology, includes item properties as well as annotation classes **/
  const includeClassToDataset = (datasetId: number, classPayload: OntologyClass): void => {
    if (isTeamItemProperty(classPayload)) {
      propertyStore.updateProperty({
        ...classPayload,
        property_values: [],
        dataset_ids: [...(classPayload.dataset_ids || []), datasetId],
      })
      return
    }
    if (!datasetStore.dataset || datasetStore.dataset.id !== datasetId) {
      return
    }
    classStore.includeInDataset(classPayload, datasetStore.dataset)
  }

  const excludeClassFromDataset = (datasetId: number, classPayload: OntologyClass): void => {
    if (isTeamItemProperty(classPayload)) {
      propertyStore.updateProperty({
        ...classPayload,
        property_values: [],
        dataset_ids: without(classPayload.dataset_ids || [], datasetId),
      })
      return
    }
    if (!datasetStore.dataset) {
      return
    }
    classStore.excludeFromDataset(classPayload, datasetStore.dataset)
  }

  /**
   * Method to get all properties part of an ontology class.
   * In case of an item level property, this method will return the property itself.
   * When the class is an annotation class, then all attached properties will be returned.
   * This method is mainly used in the class editor to show the properties to edit.
   */
  const getClassProperties = (classPayload: OntologyClass): Property[] => {
    if (isTeamItemProperty(classPayload)) {
      return [teamToClassProperty(classPayload)]
    }
    const itemProperties: Property[] = []
    propertiesStore.properties.forEach((property) => {
      if (property.annotation_class_id === classPayload.id) {
        itemProperties.push(teamToClassProperty(property))
      }
    })
    return orderBy(
      itemProperties,
      (property) => property.inserted_at + property.id,
      SortDirection.ASCENDING,
    )
  }

  /**
   * Returns a filtered list of item properties that are added to the current dataset.
   * The list is also ordered by creation date ASC
   **/
  const datasetItemProperties = computed(() => {
    const datasetId = datasetStore.currentDataset?.id
    if (!datasetId) {
      return []
    }
    const datasetList = propertiesStore.properties.filter(
      (property) => property.dataset_ids?.includes(datasetId) && isTeamItemProperty(property),
    )
    const collator = new Intl.Collator()
    const comparer = (a: TeamProperty, b: TeamProperty): number =>
      collator.compare(a.inserted_at, b.inserted_at)
    return datasetList.sort(comparer)
  })

  const isClassInDataset = (datasetId: number, classPayload: OntologyClass): boolean => {
    if (isTeamItemProperty(classPayload)) {
      return classPayload.dataset_ids?.includes(datasetId)
    }
    return classPayload.datasets.some((d) => d.id === datasetId)
  }

  /** returns the correct copy based on the class type **/
  const getClassCopy = (
    classPayload: OntologyClass | MaybeProperty,
  ): (typeof generalUICopy)['classItemProperties'] | (typeof generalUICopy)['classProperties'] =>
    isTeamItemProperty(classPayload)
      ? generalUICopy.classItemProperties
      : generalUICopy.classProperties

  const canBeDeleted = (classPayload: OntologyClass): boolean =>
    !isAnnotationClass(classPayload) || !classPayload.deletion_blocked

  const canBeRendered = (classPayload: OntologyClass): boolean => {
    if (isTeamItemProperty(classPayload)) {
      return true
    }
    const type = getMainAnnotationType(classPayload.annotation_types)
    if (!type) {
      return false
    }
    return classStore.isAnnotationTypeRenderable(type) && classStore.isAnnotationTypeEnabled(type)
  }

  // Filter and order
  const searchKeyword = ref<string>('')
  const search = (k: string): void => {
    searchKeyword.value = k.toLowerCase()
  }
  const typeFilters = ref<OntologyClassType[]>([])
  const isOfTypes = (ontologyClass: OntologyClass, types: OntologyClassType[]): boolean => {
    if (types.length === 0) {
      return true
    }
    if (isTeamItemProperty(ontologyClass)) {
      return types.includes(ontologyClass.type)
    }
    if (isAnnotationClass(ontologyClass)) {
      return types.some((type) => ontologyClass.annotation_types.includes(type as AnnotationType))
    }
    return false
  }
  const setTypeFilter = (filter: OntologyClassType[]): void => {
    typeFilters.value = filter
  }

  const sortBy = ref<OntologyClassSortType>('inserted_at')
  const sortDirection = ref<SortDirectionType>(SortDirection.DESCENDING)
  const setSort = (by: OntologyClassSortType): void => {
    sortBy.value = by
  }
  const setSortDirection = (direction: SortDirectionType): void => {
    sortDirection.value = direction
  }

  const getInsertedAtKey = <T extends AnnotationClassPayload | TeamItemProperty>(c: T): string =>
    `${c.inserted_at.replace('Z', '')}-${c.id}`

  const applySort = (items: OntologyClass[]): OntologyClass[] => {
    const list = new Map<string, OntologyClass>()
    const results = []

    // Use only 1 cycle to go through all classes
    for (const ontologyClass of items) {
      // store the sorting property as the key field for the Map for faster sorting
      const key: number | string = ontologyClass[sortBy.value]
      if (sortBy.value === 'inserted_at') {
        // inserted at is an ISO-8601 timestmap, which is sortable alphabetically
        // multiple classes could and do have the same timestmap,
        // so we append {-id} to the end, which still keeps it sortable
        list.set(getInsertedAtKey(ontologyClass), ontologyClass)
      } else {
        // Convert to lowercase for case-insensitive sorting if string
        list.set(key.toLocaleLowerCase(), ontologyClass)
      }
    }

    // according to docs, this is the fastest overall way to sort
    // in theory, String.prototype.localeCompare might be faster in some cases on chrome
    const collator = new Intl.Collator() // precreate instance to reuse it
    const comparer = // precreate comparer to avoid checking direction on every comparison
      sortDirection.value === 'asc'
        ? collator.compare
        : (a: string, b: string): number => collator.compare(b, a)
    // get all the keys (sorting property) and sort them
    const sortedKeys = Array.from(list.keys()).sort(comparer)

    // Get all items in the map in the sorted order
    for (const sortKey of sortedKeys) {
      const value = list.get(sortKey)
      value && results.push(value)
    }

    return results
  }

  const sortedDatasetClasses = computed(() => applySort(allDatasetClasses.value))

  const sortedUnassignedClasses = computed(() => applySort(allUnassignedClasses.value))

  /** same as `allDatasetClasses`, but deals with the current filter **/
  const filteredDatasetClasses = computed(() =>
    sortedDatasetClasses.value.filter(
      (c) =>
        (searchKeyword.value === '' || c.name.toLowerCase().indexOf(searchKeyword.value) > -1) &&
        isOfTypes(c, typeFilters.value),
    ),
  )

  /** same as `allUnassignedClasses`, but deals with the current filter **/
  const filteredUnassignedClasses = computed(() =>
    sortedUnassignedClasses.value.filter(
      (c) =>
        c.name.toLowerCase().indexOf(searchKeyword.value) > -1 && isOfTypes(c, typeFilters.value),
    ),
  )

  // Property creation
  /**
   * This is an helper structure while creating a new item property.
   * As this is a 2 steps process, this variable is populated to create a ghost property to fill
   * the property values before saving, as per BE design.
   */
  const creatingProperty = ref<Partial<TeamItemProperty | null>>(null)

  // Editing
  /** just the id of the class being edited **/
  const editClassId = ref<OntologyClass['id'] | null>(null)

  /** Returns the class that is currently in the edit form */
  const editClass = computed<OntologyClass | null>(() => {
    const classId = editClassId.value
    if (!classId) {
      return null
    }
    const editClass = classesById.value[classId as number]
    if (editClass) {
      return editClass as OntologyClass
    }

    const editPropertyClass = propertiesStore.properties.find(
      (p) => p.id === String(editClassId.value),
    )
    if (editPropertyClass) {
      return editPropertyClass as OntologyClass
    }
    return null
  })

  const setEditClassId = (classId: OntologyClass['id']): void => {
    editClassId.value = classId
  }

  const unsetEditClassId = (): void => {
    editClassId.value = null
  }

  // Selection
  const selectedClassIds = ref<PartialRecord<OntologyClass['id'], boolean>>({})

  const selectedClasses = computed(() => {
    const selected: OntologyClass[] = []

    Object.keys(selectedClassIds.value).forEach((selectedId) => {
      // ids from both annotation class and item properties are strings at this point, but
      // item properties have uuid ids
      if (!selectedClassIds.value[selectedId]) {
        return
      }
      if (isUUID(selectedId)) {
        const itemPropertyClass = itemProperties.value.find((p) => p.id === selectedId)
        if (itemPropertyClass) {
          selected.push(itemPropertyClass)
        }
        return
      }
      // check if the string is number like, indicating it's a valid annotation class id
      if (!isNaN(Number(selectedId))) {
        const annotationClass = classStore.teamClasses.find((c) => c.id === Number(selectedId))
        if (annotationClass) {
          selected.push(annotationClass)
        }
      }
    })

    return selected
  })

  const selectedClassesCount = computed(
    () => Object.values(selectedClassIds.value).filter((selected) => selected).length,
  )

  const selectClass = (classId: OntologyClass['id'], selected: boolean = true): void => {
    selectedClassIds.value = {
      ...selectedClassIds.value,
      [classId]: selected,
    }
  }

  const selectAllClasses = (selected: boolean): void => {
    selectedClassIds.value = {}
    classStore.teamClasses.forEach((annotationClass) => {
      if (annotationClass.deletion_blocked) {
        return
      }
      selectedClassIds.value[annotationClass.id] = selected
    })
    itemProperties.value.forEach(
      (itemPropertyClass) => (selectedClassIds.value[itemPropertyClass.id] = selected),
    )
  }

  const unselectAllClasses = (): void => {
    selectedClassIds.value = {}
  }

  const isSelectedById = (classId: OntologyClass['id']): boolean =>
    !!selectedClassIds.value[classId]

  const allLoaded = computed(
    () => classStore.loadingStatus === LoadingStatus.Loaded && propertiesStore.loaded,
  )

  return {
    allLoaded,
    // Dataset
    allDatasetClasses,
    allUnassignedClasses,
    datasetItemProperties,
    includeClassToDataset,
    excludeClassFromDataset,
    classInDataset: isClassInDataset,

    // Property creation
    creatingProperty,

    // Edit
    editClass,
    editClassId,
    setEditClassId,
    unsetEditClassId,

    // Selection
    selectedClassIds,
    selectedClasses,
    selectedClassesCount,
    selectClass,
    selectAllClasses,
    unselectAllClasses,
    isSelectedById,
    isOfTypes,

    // Properties
    getClassProperties,

    // Filter and order
    search,
    setTypeFilter,
    searchKeyword,
    typeFilters,
    countByType,
    allTypes,
    filteredDatasetClasses,
    filteredUnassignedClasses,
    sortBy,
    sortDirection,
    setSort,
    setSortDirection,

    // Utilities
    getClassCopy,
    canBeDeleted,
    canBeRendered,
  }
})
