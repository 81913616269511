<template>
  <DarwinDialog
    :open="open"
    :class-name="className"
    :nudge-top="nudgeTop"
    :nudge-right="nudgeRight"
    :nudge-bottom="nudgeBottom"
    :nudge-left="nudgeLeft"
    :max-height="maxHeight"
    :min-height="minHeight"
    :show-overlay="showOverlay"
    :scrollable="scrollable"
    :fullscreen="fullscreen"
    :click-outside-to-close="clickOutsideToClose"
    @click:outside="$emit('update:open', false)"
    @update:open="$emit('update:open', $event)"
  >
    <div
      class="confirm-dialog"
      :class="{
        'confirm-dialog--loading': loading,
        [`confirm-dialog--variant-${variant}`]: true,
        [`confirm-dialog--size-${size}`]: true,
      }"
    >
      <div class="confirm-dialog__content">
        <div
          v-if="variant !== 'default'"
          class="confirm-dialog__icon"
          :class="{ [`confirm-dialog__icon--${iconColor}`]: true }"
        >
          <Icon name="mono-warning" />
        </div>
        <div class="confirm-dialog__title">
          <slot name="title" />
          <IconButton
            v-if="!hideClose"
            flair="rounded"
            kind="transparent"
            size="xs"
            class="confirm-dialog__close"
            @click="$emit('cancel')"
          >
            <Icon name="mono-close" />
          </IconButton>
        </div>
        <div class="confirm-dialog__description">
          <slot name="detail" />
        </div>
      </div>

      <div class="confirm-dialog__body">
        <slot name="body"></slot>
      </div>

      <div
        v-if="!hideFooter"
        class="confirm-dialog__footer"
        :class="{ 'confirm-dialog__footer--sloted': $slots['dont-ask-again'] }"
      >
        <div
          v-if="$slots['dont-ask-again']"
          class="confirm-dialog__footer__dont-ask-again"
        >
          <slot name="dont-ask-again" />
        </div>
        <div class="confirm-dialog__footer__actions">
          <slot name="footer-buttons">
            <DarwinButton
              class="confirm-dialog__footer__cancel"
              size="md"
              flair="rounded"
              kind="outline"
              :full-width="!$slots['dont-ask-again']"
              @click="$emit('cancel')"
            >
              <slot name="cancel-text"> Cancel </slot>
            </DarwinButton>
            <DarwinButton
              class="confirm-dialog__footer__confirm"
              :kind="buttonKind"
              size="md"
              flair="rounded"
              :disabled="disabledConfirmAction"
              :full-width="!$slots['dont-ask-again']"
              @click="$emit('confirm')"
            >
              <slot name="confirm-text"> Confirm </slot>
              <template
                v-if="$slots['confirm-icon-shortcut']"
                #icon-shortcut
              >
                <slot name="confirm-icon-shortcut" />
              </template>
            </DarwinButton>
          </slot>
        </div>
      </div>
    </div>
  </DarwinDialog>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { computed, defineComponent } from 'vue'

import DarwinButton from '@/uiKit/Button/DarwinButton.vue'
import IconButton from '@/uiKit/Button/IconButton.vue'
import Icon from '@/uiKit/Icon/Icon.vue'
import DarwinDialog from '@/uiKit/Dialog/DarwinDialog.vue'

export default defineComponent({
  name: 'ConfirmDialog',
  components: {
    DarwinButton,
    DarwinDialog,
    IconButton,
    Icon,
  },
  props: {
    variant: {
      default: 'default',
      type: String as PropType<'default' | 'warn' | 'warning' | 'error' | 'negative'>,
    },
    size: {
      default: 'xs',
      type: String as PropType<'xs' | 'sm'>,
    },
    className: { default: undefined, type: String },
    open: { default: false, type: Boolean },
    loading: { default: false, type: Boolean },
    nudgeTop: { default: 0, type: [String, Number] },
    nudgeRight: { default: 0, type: [String, Number] },
    nudgeBottom: { default: 0, type: [String, Number] },
    nudgeLeft: { default: 0, type: [String, Number] },
    maxHeight: { default: 360, type: [String, Number] },
    minHeight: { default: 30, type: [String, Number] },
    scrollable: { default: true, type: Boolean },
    showOverlay: { default: true, type: Boolean },
    fullscreen: { default: true, type: Boolean },
    hideFooter: { default: false, type: Boolean },
    hideClose: { default: false, type: Boolean },
    clickOutsideToClose: { default: true, type: Boolean },
    disabledConfirmAction: { default: false, type: Boolean },
  },
  setup(props) {
    const iconColor = computed(() => {
      if (props.variant === 'warn' || props.variant === 'warning') {
        return 'orange'
      }
      return 'red'
    })

    const buttonKind = computed(() => {
      if (props.variant === 'warn' || props.variant === 'warning') {
        return 'primary'
      }
      if (props.variant === 'error' || props.variant === 'negative') {
        return 'negative'
      }
      return 'primary'
    })

    return {
      iconColor,
      buttonKind,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/uiKit/assets/borders.scss';
@import '@/uiKit/assets/spacings.scss';

.confirm-dialog {
  border-radius: $borderRadius16;
  position: relative;

  &--size-xs {
    width: 480px;
  }

  &--size-sm {
    width: 580px;
  }

  &__close {
    position: absolute;
    top: $spacing-5;
    right: $spacing-5;
    color: var(--color-icon-subtlest);
    font-size: 20px;
  }

  &__content {
    padding: $spacing-5;
    width: 100%;
  }

  &__icon {
    width: 100%;
    @include row--center;
    width: 28px;
    height: 28px;
    border-radius: 100px;
    margin-bottom: $s-lg;
    &--red {
      background-color: var(--color-background-critical-subtle);
      color: var(--color-icon-critical);
    }
    &--orange {
      background-color: var(--color-background-warning-subtle);
      color: var(--color-icon-warning);
    }
  }

  &__title {
    @include typographyBoldBody400;
    color: var(--color-text-default);
    width: 100%;
    margin-bottom: $s-md;
    display: flex;
    .confirm-dialog--variant-default & {
      @include typographyBoldBody500;
      margin-bottom: $s-2xs;
    }
  }

  &__description {
    @include typographyRegularBody200;
    color: var(--color-text-subtlest);
    width: 100%;
  }

  &__footer {
    padding: $spacing-5;
    width: 100%;
    display: flex;
    gap: 8px;
    align-items: center;
    background-color: var(--color-background-gray-subtlest);
    border-top: 1px solid var(--color-border-default);
    border-bottom-right-radius: $borderRadius16;
    border-bottom-left-radius: $borderRadius16;

    &__dont-ask-again {
      width: 100%;
    }

    &__actions {
      display: flex;
      gap: 8px;
      justify-content: space-between;
      width: 100%;
      .confirm-dialog__footer--sloted & {
        width: auto;
      }
    }
  }
}
</style>
