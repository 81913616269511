import type { Module, MutationTree } from 'vuex'

import type {
  AnnotationClassPayload,
  DatasetItemCountsPayload,
  DatasetPayload,
  DatasetReportPayload,
  RootState,
  V2DatasetGeneralCountsPayload,
  V2DatasetItemPayload,
} from '@/store/types'
import type { ModelTemplatePayload } from '@/backend/wind/types'

// will soon be moved so temporary eslint-disable
// eslint-disable-next-line boundaries/element-types
import { validateNewModel } from '@/modules/Models/modelCreationUtils'

import { ModelType } from '@/core/annotations'

import type { TypedMutation } from '@/store/types'

// will soon be moved so temporary eslint-disable
// eslint-disable-next-line boundaries/element-types
import type { NeuralModelValidationErrors } from '@/modules/Models/modelCreationTypes'

export type NeuralModelMutation<R> = TypedMutation<NeuralModelState, R>

export type NeuralModelState = {
  modelTemplates: ModelTemplatePayload[]

  /**
   * Used during model creation.
   * Allows us to filter selectable model templates.
   */
  newModelType: ModelType

  /**
   * Used during model creation.
   * Name of the training session / trained model which will be created.
   */
  newModelName: string

  /**
   * Used during model creation.
   * Dataset the new model will be trained on.
   */
  newModelDataset: DatasetPayload | null

  /**
   * Used during model creation.
   *
   * Classes defined for the currently selected dataset. This will get reset to
   * [] every time `newModelDataset` is set.
   */
  newModelAnnotationClasses: AnnotationClassPayload[]

  /**
   * Subset of `newModelAnnotationClasses` ids selected for training of a new model
   */
  newModelSelectedClassIds: AnnotationClassPayload['id'][]

  /**
   * Used during model creation
   *
   * Small batch of sample items, loaded for currently selected dataset. This
   * will get reset to [] every time `newModelDataset` is set.
   */
  newModelSampleItemsV2: V2DatasetItemPayload[]

  /**
   * Used during model creation.
   *
   * Sample items are fetched one small batch at a time. This key holds the
   * cursor pointing at the next batch to be fetched. It will get reset every
   * time a different dataset is selected.
   */
  newModelSampleItemsCursor: string | null

  /**
   * Used during model creation.
   * Type of training new model will receive.
   */
  newModelTemplate: ModelTemplatePayload | null

  /**
   * Used to show training, validation and test set counts on the third step of
   * model creation.
   */
  newModelTrainingCounts: number | null

  /**
   * Used to show class distribution of a selected dataset
   */
  newModelClassCounts: DatasetReportPayload | null

  /**
   * Used during new model creation as a way to render validation errors across
   * different steps of the creation flow.
   */
  newModelValidationErrors: NeuralModelValidationErrors
}

export const getInitialState = (): NeuralModelState => ({
  modelTemplates: [],
  newModelAnnotationClasses: [],
  newModelClassCounts: null,
  newModelDataset: null,
  newModelName: '',
  newModelSampleItemsV2: [],
  newModelSampleItemsCursor: null,
  newModelSelectedClassIds: [],
  newModelTemplate: null,
  newModelTrainingCounts: null,
  newModelType: ModelType.INSTANCE_SEGMENTATION,
  newModelValidationErrors: {},
})

const state: NeuralModelState = getInitialState()

const SET_NEW_MODEL_TYPE: NeuralModelMutation<ModelType> = (state, type) => {
  state.newModelType = type
}

const SET_NEW_MODEL_NAME: NeuralModelMutation<string> = (state, name) => {
  // strip newlines from name before commiting to store
  // wind doesn't really care about this, so not enforced from that end
  // but we face less render issues if we sanitize on frontend
  state.newModelName = name.replace(/(\r\n|\n|\r)/gm, '')
}

const SET_NEW_MODEL_TEMPLATE: NeuralModelMutation<ModelTemplatePayload> = (state, template) => {
  state.newModelTemplate = template
}

const SET_NEW_MODEL_SAMPLE_ITEMS_CURSOR: NeuralModelMutation<string | null> = (state, cursor) => {
  state.newModelSampleItemsCursor = cursor
}

export const SET_NEW_MODEL_TRAINING_COUNTS: NeuralModelMutation<DatasetItemCountsPayload | null> = (
  state,
  counts,
) => {
  state.newModelTrainingCounts = counts ? counts.item_count : null
}

const SET_NEW_MODEL_SELECTED_CLASSES: NeuralModelMutation<AnnotationClassPayload[]> = (
  state,
  classes,
) => {
  SET_NEW_MODEL_TRAINING_COUNTS(state, null)
  state.newModelSelectedClassIds = classes.map((c) => c.id)
}

const SET_NEW_MODEL_DATASET: NeuralModelMutation<DatasetPayload> = (state, dataset) => {
  if (
    // setting dataset after it was null
    (!state.newModelDataset && dataset) ||
    // setting from one dataset to another
    (state.newModelDataset && dataset && state.newModelDataset.id !== dataset.id)
  ) {
    state.newModelSampleItemsV2 = []
    SET_NEW_MODEL_SAMPLE_ITEMS_CURSOR(state, null)
    SET_NEW_MODEL_SELECTED_CLASSES(state, [])
    SET_NEW_MODEL_TRAINING_COUNTS(state, null)
  }

  state.newModelDataset = dataset
}

const SET_NEW_MODEL_ANNOTATION_CLASSES: NeuralModelMutation<AnnotationClassPayload[]> = (
  state,
  classes,
) => {
  state.newModelAnnotationClasses = classes
}

const PUSH_NEW_MODEL_SAMPLE_ITEMS_V2: NeuralModelMutation<V2DatasetItemPayload[]> = (
  state,
  items,
) => {
  const newIds = items.map((i) => i.id)
  state.newModelSampleItemsV2 = state.newModelSampleItemsV2
    .filter((i) => !newIds.includes(i.id))
    .concat(items)
}

const TOGGLE_NEW_MODEL_CLASS_SELECTION: NeuralModelMutation<AnnotationClassPayload> = (
  state,
  annotationClass,
) => {
  const idx = state.newModelSelectedClassIds.indexOf(annotationClass.id)
  if (idx === -1) {
    state.newModelSelectedClassIds.push(annotationClass.id)
  } else {
    SET_NEW_MODEL_TRAINING_COUNTS(state, null)
    state.newModelSelectedClassIds.splice(idx, 1)
  }
}

const DESELECT_ALL_NEW_MODEL_CLASSES: NeuralModelMutation<void> = (state) => {
  SET_NEW_MODEL_TRAINING_COUNTS(state, null)
  SET_NEW_MODEL_SELECTED_CLASSES(state, [])
}

const VALIDATE_NEW_MODEL: NeuralModelMutation<void> = (state) => {
  const errors = validateNewModel(state)
  state.newModelValidationErrors = errors
}

const SET_NEW_MODEL_VALIDATION_ERRORS_FROM_BACKEND: NeuralModelMutation<Record<string, string>> = (
  state,
  backendErrors,
) => {
  const errors: NeuralModelState['newModelValidationErrors'] = {}
  if (backendErrors.name) {
    errors.name = backendErrors.name
  }
  state.newModelValidationErrors = errors
}

export const SET_NEW_MODEL_CLASS_COUNTS: NeuralModelMutation<DatasetReportPayload | null> = (
  state,
  report,
) => {
  state.newModelClassCounts = report
}

export const SET_NEW_MODEL_TRAINING_COUNTS_V2: NeuralModelMutation<{
  counts: V2DatasetGeneralCountsPayload | null
  datasetId: number
}> = (state, payload) => {
  const { counts, datasetId } = payload
  if (!counts) {
    state.newModelTrainingCounts = null
    return
  }

  const datasetCounts = counts.simple_counts.find((count) => count.dataset_id === datasetId)
  if (!datasetCounts) {
    state.newModelTrainingCounts = null
    return
  }

  state.newModelTrainingCounts = datasetCounts.filtered_item_count
}

const mutations: MutationTree<NeuralModelState> = {
  DESELECT_ALL_NEW_MODEL_CLASSES,
  PUSH_NEW_MODEL_SAMPLE_ITEMS_V2,
  SET_NEW_MODEL_ANNOTATION_CLASSES,
  SET_NEW_MODEL_CLASS_COUNTS,
  SET_NEW_MODEL_DATASET,
  SET_NEW_MODEL_NAME,
  SET_NEW_MODEL_SAMPLE_ITEMS_CURSOR,
  SET_NEW_MODEL_SELECTED_CLASSES,
  SET_NEW_MODEL_TEMPLATE,
  SET_NEW_MODEL_TRAINING_COUNTS,
  SET_NEW_MODEL_TRAINING_COUNTS_V2,
  SET_NEW_MODEL_TYPE,
  SET_NEW_MODEL_VALIDATION_ERRORS_FROM_BACKEND,
  TOGGLE_NEW_MODEL_CLASS_SELECTION,
  VALIDATE_NEW_MODEL,

  SET_MODEL_TEMPLATES(state, data: ModelTemplatePayload[]) {
    state.modelTemplates = data
  },

  RESET_ALL(state: NeuralModelState) {
    state.modelTemplates = []
    state.newModelAnnotationClasses = []
    state.newModelClassCounts = null
    state.newModelDataset = null
    state.newModelName = ''
    state.newModelSampleItemsV2 = []
    state.newModelSampleItemsCursor = null
    state.newModelSelectedClassIds = []
    state.newModelTemplate = null
    state.newModelTrainingCounts = null
    state.newModelType = ModelType.INSTANCE_SEGMENTATION
    state.newModelValidationErrors = {}
  },
}

const neuralModel: Module<NeuralModelState, RootState> = {
  namespaced: true,
  state,
  mutations,
}

export default neuralModel
