import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import { useToast } from '@/uiKit/Toast/useToast'
import type { PartialRecord } from '@/core/helperTypes'
import type { AutoAnnotateModel } from '@/pinia/useAutoAnnotateStore'
import { useTeamStore } from '@/pinia/useTeamStore'
import {
  deleteExternalModel as deleteExternalModelFromWind,
  loadAvailableModels as loadAvailableModelsFromWind,
  loadExternalModels as loadExternalModelsFromWind,
  loadInferenceRequests as loadInferenceRequestsFromWind,
  loadRunningSessionInstanceCounts as loadRunningSessionInstanceCountsFromWind,
  loadRunningSessions as loadRunningSessionsFromWind,
  loadTrainedModels as loadTrainedModelsFromWind,
  loadTrainingSessions as loadTrainingSessionsFromWind,
  stopTrainingSession as stopTrainingSessionFromWind,
  updateRunningSession as updateRunningSessionFromWind,
} from '@/backend/wind'
import type {
  ExternalModelResponsePayload,
  InferenceRequestCountPayload,
  MetricPayload,
  RunningSessionInstanceCountPayload,
  RunningSessionPayload,
  TrainedModelPayload,
  TrainingSessionPayload,
} from '@/backend/wind/types'

import type { ModelItem } from './types'
import type { ApiResult } from '@/backend/darwin/types'
/**
 * This Pinia store acts as the frontend's interface to Wind. It is meant to
 * eventually fully replace the Vuex neuralModel module. If, while migrating
 * there is a clear set of responsibilities that can be moved to a separate
 * store, that should be done.
 *
 * This store's responsibilities should include:
 * - loading and storing models
 * - sending requests to update models
 * - sending requests to start/stop models
 * - sending requests to run inferences
 *
 * To enable the incremental migration from Vuex to Pinia, to begin with
 * this store will act as a proxy to the Vuex store.
 *
 */
export const useModelsStore = defineStore('models', () => {
  const toast = useToast()

  /**
   * All 'new style' external models, in the format returned by Wind.
   */
  const externalModelPayloads = ref<ExternalModelResponsePayload[]>([])

  /**
   * Both Running Sessions and External Models being fetched from the same endpoint.
   * No need for client mapping also
   */
  const availableModels = ref<AutoAnnotateModel[]>([])
  /**
   * All 'new style' external models, using the frontend's `ModelItem` type.
   */
  const externalModelItems = computed<ModelItem[]>(() =>
    externalModelPayloads.value.map((m) => ({
      id: m.id,
      name: m.name,
      teamId: m.team_id,
      insertedAt: m.inserted_at,
      datasetSlug: null,
      visible: m.visible,
    })),
  )
  const teamStore = useTeamStore()

  /**
   * Load all external models from Wind and populate in the store.
   */
  const loadExternalModels = async (): Promise<void> => {
    if (!teamStore.currentTeam) {
      return
    }

    const response = await loadExternalModelsFromWind(teamStore.currentTeam.id)
    if ('data' in response) {
      externalModelPayloads.value = response.data
    }
  }

  const loadAvailableModels = async (): Promise<void> => {
    if (!teamStore.currentTeam) {
      return
    }

    const response = await loadAvailableModelsFromWind(teamStore.currentTeam.id)
    if ('data' in response) {
      availableModels.value = response.data
    }
  }

  /**
   * Adds a new external model payload to the store. If a model with the same
   * ID already exists, it will be replaced.
   */
  const addExternalModelPayload = (payload: ExternalModelResponsePayload): void => {
    const otherModels = externalModelPayloads.value.filter((m) => m.id !== payload.id)

    externalModelPayloads.value = [...otherModels, payload]
  }

  const deleteExternalModel = async (modelId: string): Promise<void> => {
    if (!teamStore.currentTeam) {
      return
    }

    const response = await deleteExternalModelFromWind(modelId, teamStore.currentTeam.id)
    if ('error' in response) {
      toast.error({ meta: { title: 'Error deleting model. Please try again.' } })
      return
    }

    externalModelPayloads.value = externalModelPayloads.value.filter((m) => m.id !== modelId)
  }

  /**
   * All Wind running sessions
   */
  const runningSessions = ref<RunningSessionPayload[]>([])

  const selectedRunningSession = ref<RunningSessionPayload | null>(null)

  const loadRunningSessions = async (teamId: number): Promise<void> => {
    const response = await loadRunningSessionsFromWind({
      teamId,
      expand: ['meta.classes', 'meta.num_instances_available', 'meta.num_instances_starting'],
      includePublic: true,
    })
    if (response.ok) {
      runningSessions.value = response.data
    }
  }

  /**
   * All Wind trained models
   */
  const trainedModels = ref<TrainedModelPayload[]>([])

  const loadTrainedModels = async (teamId: number): Promise<void> => {
    const response = await loadTrainedModelsFromWind({ teamId })
    if (response.ok) {
      trainedModels.value = response.data
    }
  }

  /**
   * All Wind training sessions
   */
  const trainingSessions = ref<TrainingSessionPayload[]>([])

  const loadTrainingSessions = async (teamId: number): Promise<void> => {
    const response = await loadTrainingSessionsFromWind({ teamId })
    if (response.ok) {
      trainingSessions.value = response.data
    }
  }

  const runningSessionInstanceCounts = ref<RunningSessionInstanceCountPayload[]>([])

  const loadRunningSessionInstanceCounts = async (payload: {
    from: string
    granularity: 'month' | 'day' | 'hour'
    runningSession: RunningSessionPayload
    teamId: number
  }): Promise<ApiResult<RunningSessionInstanceCountPayload[]>> => {
    const response = await loadRunningSessionInstanceCountsFromWind({
      from: payload.from,
      granularity: payload.granularity,
      runningSessionId: payload.runningSession.id,
      teamId: payload.teamId,
    })

    if (response.ok) {
      runningSessionInstanceCounts.value = runningSessionInstanceCounts.value
        .filter((r) => r.running_session_id !== payload.runningSession.id)
        .concat(response.data)
    }

    return response
  }

  const runningSessionRequestCounts = ref<InferenceRequestCountPayload[]>([])

  const loadInferenceRequests = async (payload: {
    from: string
    granularity: 'month' | 'day' | 'hour' | 'minute'
    runningSession: RunningSessionPayload
    teamId: number
  }): Promise<ApiResult<InferenceRequestCountPayload[]>> => {
    const { from, granularity, teamId, runningSession } = payload
    const response = await loadInferenceRequestsFromWind({
      from,
      granularity,
      runningSessionId: runningSession.id,
      teamId,
    })

    if (response.ok) {
      runningSessionRequestCounts.value = runningSessionRequestCounts.value
        .filter((r) => r.running_session_id !== runningSession.id)
        .concat(response.data)
    }

    return response
  }

  const stopTrainingSession = async (params: {
    teamId: number
    trainingSessionId: string
  }): Promise<ApiResult<void>> => {
    const response = await stopTrainingSessionFromWind(params)
    if (response.ok) {
      trainingSessions.value = trainingSessions.value.filter(
        (s) => s.id !== params.trainingSessionId,
      )

      return { ok: true, data: undefined }
    }

    return response
  }

  const selectedTrainedModel = ref<TrainedModelPayload | null>(null)
  const selectTrainedModel = (model: TrainedModelPayload | null): void => {
    selectedTrainedModel.value = model
  }

  const selectRunningSession = (model: RunningSessionPayload | null): void => {
    selectedRunningSession.value = model
  }

  const metrics = ref<PartialRecord<string, MetricPayload[]>>({})

  const setMetricsForTrainingSession = (
    trainingSessionId: string,
    sessionMetrics: MetricPayload[],
  ): void => {
    metrics.value = {
      ...metrics.value,
      [trainingSessionId]: sessionMetrics,
    }
  }

  const pushTrainedModel = (model: TrainedModelPayload): void => {
    const index = trainedModels.value.findIndex((s) => s.id === model.id)
    if (index >= 0) {
      trainedModels.value.splice(index, 1, model)
    } else {
      trainedModels.value.push(model)
    }
  }

  const pushRunningSession = (model: RunningSessionPayload): void => {
    const index = runningSessions.value.findIndex((s) => s.id === model.id)
    if (index >= 0) {
      runningSessions.value.splice(index, 1, model)
    } else {
      runningSessions.value.push(model)
    }
  }

  const pushTrainingSession = (model: TrainingSessionPayload): void => {
    const index = trainingSessions.value.findIndex((s) => s.id === model.id)
    if (index >= 0) {
      trainingSessions.value.splice(index, 1, model)
    } else {
      trainingSessions.value.push(model)
    }
  }

  const updateModel = async (payload: {
    autoStart?: boolean
    autoStop?: boolean
    maximumInstances: number
    minimumInstances: number
    runningSession: RunningSessionPayload
    teamId: number
  }): Promise<void> => {
    const { autoStart, autoStop, maximumInstances, minimumInstances, runningSession, teamId } =
      payload

    const response = await updateRunningSessionFromWind({
      autoStart,
      autoStop,
      expand: ['meta.classes', 'meta.num_instances_available', 'meta.num_instances_starting'],
      max: maximumInstances,
      min: minimumInstances,
      runningSessionId: runningSession.id,
      teamId,
    })

    if (response.ok) {
      pushRunningSession(response.data)
    }

    if ('error' in response && typeof response.error.message === 'string') {
      toast.warning({ meta: { title: response.error.message } })
    }
  }

  const reset = (): void => {
    externalModelPayloads.value = []
    trainedModels.value = []
    runningSessions.value = []
    trainingSessions.value = []
    metrics.value = {}
    selectedRunningSession.value = null
    selectedTrainedModel.value = null
    runningSessionInstanceCounts.value = []
    runningSessionRequestCounts.value = []
  }

  return {
    // Lists of models and functions to load them
    externalModelPayloads,
    externalModelItems,
    loadExternalModels,
    addExternalModelPayload,

    // new API using the Available models endpoint
    availableModels,
    loadAvailableModels,

    trainedModels,
    loadTrainedModels,

    trainingSessions,
    loadTrainingSessions,

    runningSessions,
    loadRunningSessions,

    selectedRunningSession,
    selectRunningSession,

    // Stats on usage of models
    loadInferenceRequests,
    loadRunningSessionInstanceCounts,
    runningSessionRequestCounts,
    runningSessionInstanceCounts,

    metrics,
    setMetricsForTrainingSession,

    // Start/stop/delete models
    deleteExternalModel,
    stopTrainingSession,
    updateModel,
    selectTrainedModel,
    selectedTrainedModel,

    // Reset
    reset,

    // model data
    pushTrainedModel,
    pushRunningSession,
    pushTrainingSession,
  }
})
