/* global CanvasImageSource */
import { renderImageOnCanvas } from '@/modules/Editor/renderImageOnCanvas'
import { renderMeasureRegion } from '@/modules/Editor/renderMeasureRegion'
import { View } from '@/modules/Editor/views/view'

import { ViewTypes } from './viewTypes'
import { loadImageData, resolveTransformedImageData } from '@/modules/Editor/utils'
import type { WindowLevels } from '@/modules/Editor/imageManipulation'

export class ImageView extends View {
  readonly type = ViewTypes.IMAGE

  private _lastWindowLevels: WindowLevels | null = null

  private _rawData: ImageData | null = null
  private _transformedData: CanvasImageSource | null = null

  private _releaseBeforeRender: () => void = () => {}
  private _releaseRender: () => void = () => {}

  resolveFrameData(): void {
    if (!this.currentFrame) {
      return
    }

    const rawData = this._rawData || loadImageData(this.currentFrame.data)
    if (!rawData) {
      throw new Error('Failed to load image data')
    }
    const transformedData = this._transformedData

    const resolved = resolveTransformedImageData(
      this._lastWindowLevels,
      this.imageFilter.windowLevels,
      rawData,
      transformedData,
      this.currentFrame.data,
    )

    if (!resolved) {
      return
    }

    this._rawData = rawData
    this._transformedData = resolved
    this._lastWindowLevels = this.imageFilter.windowLevels
  }

  renderFrame(canvas: HTMLCanvasElement): void {
    if (!this.currentFrame) {
      return
    }
    const transformedData = this._transformedData
    transformedData &&
      renderImageOnCanvas(
        canvas,
        this.currentFrame.data,
        transformedData,
        this.camera,
        this.imageFilter,
      )
  }

  init(): void {
    this.cleanupCacheData()
    this.fileManager.loadFrames()

    this.showFramesTool = false
    this._releaseBeforeRender = this.mainLayer.onBeforeRender(() => this.resolveFrameData()).release

    this._releaseRender = this.mainLayer.onRender((ctx, canvas) => {
      this.renderFrame(canvas)
      this.currentFrame && this.editor.renderMeasures && renderMeasureRegion(this)
    }).release
    this.mainLayer.clear()

    // Reset the current image filter's window level
    this.setImageFilter({
      ...this.imageFilter,
      windowLevels: this.defaultWindowLevels,
    })

    // Reset tool
    const { currentTool } = this.toolManager
    if (currentTool) {
      currentTool.tool.reset(currentTool.context)
    }

    this.jumpToFrameAndInitCamera()
  }

  private async jumpToFrameAndInitCamera(): Promise<void> {
    await this.jumpToFrame(0)
    this.initCamera()
  }

  private cleanupCacheData(): void {
    this._releaseBeforeRender()
    this._releaseRender()

    if (this.currentFrame) {
      this._rawData = null
      this._transformedData = null
    }

    this._lastWindowLevels = null
  }

  cleanup(): void {
    this.cleanupCacheData()
    this.mainLayer.clear()

    super.cleanup()
  }
}
