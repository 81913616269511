import Vue from 'vue'
import Vuex, { Store } from 'vuex'

import { BUILD_ENVIRONMENT } from '@/services/config'

import dataset, { getInitialState as getDatasetInitialState } from './modules/dataset'
import datasetItemReports, {
  getInitialState as getDatasetItemReportsState,
} from './modules/datasetItemReports'
import datasetUpload, {
  getInitialState as getDatasetUploadInitialState,
} from './modules/datasetUpload'
import neuralModel, { getInitialState as getNeuralModelInitialState } from './modules/neuralModel'
import team, { getInitialState as getTeamInitialState } from './modules/team'
import ui, { getInitialState as getUIInitialState } from './modules/ui'
import workview, { getInitialState as getWorkviewInitialState } from './modules/workview'

import type { RootState } from './types'

Vue.use<RootState>(Vuex)

export const createStore = (): Store<RootState> =>
  new Store<RootState>({
    modules: {
      dataset,
      datasetUpload,
      neuralModel,
      team,
      ui,
      workview,
      datasetItemReports,
    },
    // strict mode is safe, but slow, so useful during development,
    // but should be off in prod
    strict: BUILD_ENVIRONMENT !== 'production',
  })

const store = createStore()

export const getInitialState = (): RootState => ({
  dataset: getDatasetInitialState(),
  datasetUpload: getDatasetUploadInitialState(),
  neuralModel: getNeuralModelInitialState(),
  team: getTeamInitialState(),
  ui: getUIInitialState(),
  workview: getWorkviewInitialState(),
  datasetItemReports: getDatasetItemReportsState(),
})

export const resetStore = (): void => store.replaceState(getInitialState())

export default store
